.form-element {
  &:not(.form-element-inline) {
    @apply flex flex-col mb-4;
    .form-label {
      @apply mb-2;
    }
    .form-hint,
    .form-error,
    .form-success {
      @apply mt-2;
    }
  }
  &.form-element-inline {
    @apply flex flex-row items-center justify-start;
    .form-label {
      @apply whitespace-no-wrap;
    }
    > * {
      @apply mr-2;
    }
  }

  .form-input,
  .form-textarea {
    @apply block w-full;
    font-size: 16px;
    &.form-input-valid {
      @apply border border-green-500;
    }

    &.form-textarea-payment {
      @apply border-2 border-mila-paymentTools text-lg;
    }

    &.form-input-payment {
      @apply border-2 border-mila-paymentTools text-lg;
    }

    &.form-input-payment-invalid {
      @apply border-2 border-red-500 text-lg;
    }

    &.form-input-payment-sm {
      @apply border-2 border-mila-paymentTools;
    }

    &.form-input-payment-sm-invalid {
      @apply border-2 border-red-500;
    }

    &.form-input-payment-date {
      @apply border border-grey-card text-center text-xl w-12 h-12;
    }
    &.form-input-payment-date-valid {
      @apply border-2 border-mila-paymentTools text-center text-xl w-12 h-12;
    }
    &.form-input-payment-date-invalid {
      @apply border-2 border-red-500 text-center text-xl w-12 h-12;
    }

    &.form-input-payment-date-sm {
      @apply border border-grey-card text-center text-sm w-10 h-10;
    }
    &.form-input-payment-date-sm-valid {
      @apply border-2 border-mila-paymentTools text-center text-sm w-10 h-10;
    }
    &.form-input-payment-date-sm-invalid {
      @apply border-2 border-red-500 text-center text-sm w-10 h-10;
    }

    &.form-input-invalid {
      @apply border border-red-500;
    }
    &.form-input-send {
      @apply border-b-2 rounded-none border-l-0 border-t-0 border-r-0 border-white bg-transparent text-4xl py-0 px-2;
    }
    &.form-input-sendConv {
      @apply border-b-2 border-l-0 border-t-0 border-r-0 border-white bg-transparent rounded-none text-4xl py-0 px-0 w-20 text-center;
    }
    &.form-input-sendSm {
      @apply border-b-2 rounded-none border-l-0 border-t-0 border-r-0 border-white bg-transparent text-3xl py-0 px-2;
    }
    &.form-input-sendConvSm {
      @apply border-0 bg-transparent rounded-none text-3xl py-0 px-0 w-20 text-center;
    }
    &.form-input-sendAddress {
      @apply border border-grey-card  text-grey-card text-lg py-1 px-4;
    }

    &.form-input-sendAddressSm {
      @apply border border-grey-card  text-grey-card text-base py-1 px-4;
    }
    [data-background='dark'] & {
      &.form-input-invalid {
        @apply border border-red-500;
      }
      @apply bg-grey-800 border-grey-700 text-white;
      &::placeholder {
        color: theme('colors.red');
      }
      &.form-input-send {
        @apply border-b-2 rounded-none border-l-0 border-t-0 border-r-0 border-white bg-transparent text-4xl py-0 px-2;
      }
      &.form-input-sendConv {
        @apply border-0 rounded-none bg-transparent text-4xl py-0 px-0 w-20 text-center;
      }
      &.form-input-sendAddress {
        @apply border border-grey-card text-grey-card text-lg py-1 px-4;
      }
      &.form-input-sendSm {
        @apply border-b-2 rounded-none border-l-0 border-t-0 border-r-0 border-white bg-transparent text-3xl py-0 px-2;
      }
      &.form-input-sendConvSm {
        @apply border-0 bg-transparent rounded-none text-3xl py-0 px-0 w-20 text-center;
      }
      &.form-input-sendAddressSm {
        @apply border border-grey-card  text-grey-card text-base py-1 px-4;
      }
    }
  }
  .form-select {
    @apply block w-full;
    font-size: 16px;
    &.form-select-valid {
      @apply border border-green-500;
    }
    &.form-select-invalid {
      @apply border border-red-500;
    }
    &.form-select-payment {
      @apply border-2 border-mila-paymentTools text-lg;
    }
    &.form-select-payment-invalid {
      @apply border-2 border-red-500 text-lg;
    }
    [data-background='dark'] & {
      @apply bg-grey-800 border-grey-700 text-white;
    }

    &.form-select-payment-sm {
      @apply border-2 border-mila-paymentTools;
    }
    &.form-select-payment-sm-invalid {
      @apply border-2 border-red-500;
    }
    [data-background='dark'] & {
      @apply bg-grey-800 border-grey-700 text-white;
    }
  }
  .form-radio {
    &.form-radio-valid {
      @apply text-green-500;
      + span {
        @apply text-green-500;
      }
    }
    &.form-radio-invalid {
      @apply text-red-500;
      + span {
        @apply text-red-500;
      }
    }
  }
  .form-hint {
    @apply text-xs text-grey-500 whitespace-no-wrap;
  }
  .form-error {
    @apply text-xs text-red-500 whitespace-no-wrap;
  }
  .form-success {
    @apply text-xs text-green-500 whitespace-no-wrap;
  }
}
.form-icon {
  @apply h-4 w-4 stroke-current absolute bottom-0;
  margin-bottom: 10px;
  &.form-success {
    @apply text-red-500;
  }
  &.form-error {
    @apply text-green-500;
  }
  @apply right-0 mr-2;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.meter {
  /* Reset the default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  margin: 0 auto 1em;
  width: 100%;
  height: 0.5rem;
  transition: background-color 0.5s ease;

  /* Applicable only to Firefox */
  background: none;
  background-color: rgba(0, 0, 0, 0.1);
}

.meter::-webkit-meter-bar {
  background: none;
  background-color: rgba(0, 0, 0, 0.1);
}

.meter[value="1"] {
  weight: "25%";
  background-color: #f91c51;
}
.meter[value="2"] {
  weight: "50%";
  background-color: #f9cf1c;
}
.meter[value="3"] {
  weight: "75%";
  background-color: #ff7300;
}
.meter[value="4"] {
  weight: "100%";
  background-color: #0ebc00;
}

/* Gecko based browsers */
.meter[value="1"]::-moz-meter-bar {
  weight: "25%";
  background: #f91c51;
}
.meter[value="2"]::-moz-meter-bar {
  weight: "50%";
  background: #f9cf1c;
}
.meter[value="3"]::-moz-meter-bar {
  weight: "75%";
  background: #ff7300;
}
.meter[value="4"]::-moz-meter-bar {
  weight: "100%";
  background: #0ebc00;
}

/*  Remove Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hpRegFont {
  font-family: hpreg;
}

.hpBoldFont {
  font-family: hpBold;
}

.hpItalicBoldFont {
  line-height: normal;
  font-family: hpItalicBold;
}

.roundCountSize {
  height: 19rem;
  width: 19rem;
}

.animate-floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.animate-floating2 {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
}

#root {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  position: relative;
}

.plus-add {
  color: #de2480;
  margin-top: 1rem;
  font-weight: 900;
}

.link {
  @apply text-blue-500;
  @apply cursor-pointer;
}
.link:hover {
  @apply text-mila-400;
}

.shadow-outline-white {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 1) !important;
}
.shadow-outline-black {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 1) !important;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.react-icons {
  vertical-align: middle;
}

.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.bg-1 {
  background: url(/bg-login-1.jpg) no-repeat 0 0 fixed;
  background-size: cover;
}
.bg-login-2 {
  background: url(/bg-login-2.png) no-repeat 0 0 fixed;
  background-size: cover;
}
.bg-login-3 {
  background: url(/bg-login-2.png) no-repeat 0 0 fixed;
  background-size: cover;
}

.w-96 {
  width: calc(theme("spacing.1") * 96);
}
.w-128 {
  width: calc(theme("spacing.1") * 128);
}

.bgHeader {
  background: #100e0e 0% 0% no-repeat padding-box;
  border-radius: 37px;
  opacity: 1;
}

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.animate-fadeIn {
  -webkit-animation: fadeInApper ease 0.75s;
  animation: fadeInApper ease 0.75s;
}

.animate-fadeOut {
  -webkit-animation: fadeInApper ease 0.75s;
  animation: fadeOutApper ease 0.75s;
}

.animate-fadeInDelay {
  -webkit-animation: fadeInApper ease 0.75s;
  animation: fadeInApper ease 0.75s;
  animation-delay: 1s;
}

.animate-floatUpDown {
  -webkit-animation: floatUpDown 4s cubic-bezier(0.425, 0.05, 0.57, 0.95)
    infinite;
  animation: floatUpDown 4s cubic-bezier(0.425, 0.05, 0.57, 0.95) infinite;
}

.animate-fadeInApperDownUp {
  animation: fadeInApperDownUp 0.6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  opacity: 0;
}

.responsiveImage {
  width: 100%;
  height: auto;
}

@keyframes floatUpDown {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-40px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes fadeInApperDownUp {
  0% {
    transform: translatey(1rem);
    opacity: 0;
  }

  100% {
    transform: translatey(0%);
    opacity: 1;
  }
}

@keyframes fadeInApper {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes fadeOutApper {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(5%);
    opacity: 0;
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-apperTop {
  -webkit-animation: fadeInApper ease 1.5s;
  animation: fadeInTopApper ease 1.5s;
}

@keyframes fadeInTopApper {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.animate-apperBottom {
  -webkit-animation: fadeInApper ease 1.5s;
  animation: fadeInBotApper ease 1.5s;
}

.animate-apperBottom3 {
  -webkit-animation: fadeInApper ease 3s;
  animation: fadeInBotApper ease 3s;
}

@keyframes fadeInBotApper {
  0% {
    transform: translateY(5%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

/* social media colors */
.text-facebook {
  color: #365397;
}
.text-twitter {
  color: #00a9f1;
}
.text-github {
  color: #2f2f2f;
}

.text-linkedin {
  color: #006db3;
}
.text-apple {
  color: #737373;
}
.text-google {
  color: #4285f4;
}
.text-google-plus {
  color: #e0452c;
}
.text-youtube {
  color: #ff3333;
}
.text-vimeo {
  color: #48b6ed;
}
.text-pinterest {
  color: #ce1a19;
}
.text-yelp {
  color: #c30f00;
}
.text-dribbble {
  color: #ed4584;
}
.text-amazon {
  color: #ff9700;
}
.text-skype {
  color: #00acf4;
}
.text-instagram {
  color: #396d9a;
}
.text-dropbox {
  color: #0d84de;
}
.text-flickr {
  color: #ea0066;
}
.text-tumblr {
  color: #304c68;
}
.text-foursquare {
  color: #207dc5;
}

.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

.image-grey {
  filter: grayscale(100%);
}

.bg-la-widget {
  width: 35rem;
  height: 25rem;
  background-image: url("../assets/homepage/widget.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.homepage-header {
  animation: slide-left 0.5s cubic-bezier(0, 0.5, 0, 1) forwards;
  background-color: #202020;
  clip-path: circle(73.1% at 17% 46%);
}

@keyframes slide-left {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: none;
  }
}

.popText {
  animation: pop-in 0.6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  opacity: 0;
}

.subtitlePop {
  animation-delay: 0.6s;
}

.titlePop {
  animation-delay: 0.9s;
}

.subtextPop {
  animation-delay: 1.2s;
}

.button1Pop {
  animation-delay: 1.5s;
}

.button2Pop {
  animation-delay: 1.7s;
}

.integrateDelay {
  animation-delay: 10s;
}

.slideRight {
  opacity: 1;
}

.slideRight:hover {
  animation: slide-right 1.2s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  animation-delay: 0.4s;
}

@keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(1.5rem);
  }
  100% {
    transform: translateX(1.3rem);
  }
}

.enterLoading {
  animation: fade-just-fade 0.9s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  opacity: 0;
}

@keyframes fade-just-fade {
  0% {
    opacity: 0;
    transform: scale(65%);
  }
  95% {
    opacity: 1;
    transform: scale(110%);
  }
  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

.hide {
  position: absolute;
  opacity: 0;
  z-index: -100;
  white-space: pre;
}

.bg-sendBg {
  background: -webkit-linear-gradient(90deg, #ff0000, #9429ff);
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: translateY(-2rem) scale(0.7);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.forWhoCP {
  clip-path: ellipse(74% 85% at 23% 11%);
}

.bgHeaderNew {
  clip-path: polygon(0% 0%, 0% 100%, 19% 100%, 100% 0%);
  background: -webkit-linear-gradient(-75deg, #e9217a, #ae4dbc);
  width: 100%;
  height: 100%;
}

.bgHeaderNewShort {
  clip-path: polygon(79% 0%, 0% 0%, 0% 100%, 57% 100%);
  background: -webkit-linear-gradient(-75deg, #e9217a, #ae4dbc);
  width: 100%;
  height: 100%;
}

.bgHeaderNewPhone {
  clip-path: polygon(0% 0%, 0% 69%, 100% 43%, 100% 0%);
  background: -webkit-linear-gradient(-90deg, #e9217a, #ae4dbc);
  width: 100%;
  height: 100%;
}

.bgSecurityNew {
  clip-path: polygon(0% 0%, 0% 85%, 100% 100%, 100% 15%);
  background: -webkit-linear-gradient(-75deg, #e9217a, #ae4dbc);
  background-size: cover;
  width: 100%;
  height: 100%;
}

.bgSecurityNewPhone {
  /* clip-path: polygon(0% 0%, 0% 90%, 100% 99%, 100% 11%); */
  background: -webkit-linear-gradient(-90deg, #e9217a, #ae4dbc);
  width: 100%;
  height: 100%;
}

.text-milaColors {
  background: -webkit-linear-gradient(75deg, #eb0841, #ab20fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.homepage-header-back {
  background-color: #fd4f7c;
  opacity: 0.47;
  clip-path: circle(73.1% at 17% 46%);
}

.homepage-header-docs {
  background-color: #202020;
  clip-path: circle(64% at 27% 45%);
  background-position: right;
}

.homepage-header-docs-back {
  background-color: #fd4f7c;
  opacity: 0.47;
  clip-path: circle(69% at 27% 45%);
  background-position: right;
}

.homepage-header-sm {
  background-color: #202020;
  clip-path: circle(48.2% at 50% 47%);
  background-position: right;
}

.homepage-header-back-sm {
  background-color: #fd4f7c;
  opacity: 0.47;
  clip-path: circle(49.6% at 50% 47%);
  background-position: right;
}

.aboutPoly {
  clip-path: ellipse(70% 50% at 75% 37%);
  background-position: right;
}

.bg-eCommerce {
  clip-path: circle(50% at 50% 50%);
  background-image: url("../assets/homepage/forwho/ecommersInner.png");
  background-size: cover;
  background-position: center;
}

.bg-mila-btnwidget {
  background: linear-gradient(
    to bottom right,
    #fb7200 0%,
    #de252f 35%,
    #8403a3 85%,
    #ab20fd 100%
  );
}

.bg-IT {
  clip-path: circle(50% at 50% 50%);
  background-image: url("../assets/homepage/forwho/itInner.png");
  background-size: cover;
  background-position: center;
}

.bg-Charities {
  clip-path: circle(50% at 50% 50%);
  background-image: url("../assets/homepage/forwho/charityInner.png");
  background-size: cover;
  background-position: center;
}

.bg-Merchant {
  clip-path: circle(50% at 50% 50%);
  background-image: url("../assets/homepage/forwho/merchantInner.png");
  background-size: cover;
  background-position: center;
}

.bg-Professional {
  clip-path: circle(50% at 50% 50%);
  background-image: url("../assets/homepage/forwho/professionalInner.png");
  background-size: cover;
  background-position: center;
}

.bg-headerDoc {
  background-image: url("../assets/homepage/docs/docHeader.png");
  background-size: cover;
  background-position: right;
}

.bg-headerCareers {
  background-image: url("../assets/homepage/careers/careersHeader.png");
  background-size: cover;
  background-position: center;
}

.bg-headerJobs {
  background-image: url("../assets/homepage/careers/jobHeader.png");
  background-size: cover;
  background-position: center;
}

.bg-login {
  background-image: url("../assets/homepage/registration/bgLogin.png");
  background-size: cover;
  background-position: top left;
}

.bg-signUp {
  background-image: url("../assets/homepage/registration/signUp.png");
  background-size: cover;
  background-position: top right;
}

.bg-howItWorks {
  background-image: url("../assets/homepage/howItWorks/bg-howIt.png");
  background-size: cover;
  background-position: right;
}

.bgFooter {
  background-image: url("../assets/homepage/footer/bgFooter.png");
  background-size: cover;
  background-position: center;
}

.bgSec1 {
  background-image: url("../assets/homepage/bg-sec1.png");
  background-size: cover;
  background-position: right;
}

.bgSec2 {
  background-image: url("../assets/homepage/bg-sec2.png");
  background-size: cover;
  background-position: right;
}

.bgSec3 {
  background-image: url("../assets/homepage/bg-sec3.png");
  background-size: cover;
  background-position: right;
}

.bgGetStarted {
  background-image: url("../assets/homepage/getStarted/cover.png");
  background-size: cover;
  background-position: top;
}

.bg-codBlSec {
  background-image: linear-gradient(to right, #444444, #202020);
}

.h-about {
  height: 35vh;
}

.parent:hover .parent-hover\:bg-mila-751 {
  background-color: rgb(241, 186, 248);
}

.parent-copy:hover .parent-copy-hover\:opacity-100 {
  opacity: 1;
}

.bgHlGetS {
  background-color: rgba(0, 0, 0, 0.4);
  clip-path: circle(47% at 40% 50%);
  background-position: right;
}

.bgEndSec {
  clip-path: polygon(0 0, 69% 8%, 100% 0, 100% 100%, 0 100%);
  background-position: center;
}

.bgErrorSec {
  clip-path: polygon(0% 0%, 0% 100%, 95% 100%, 100% 0%);
  background-position: center;
}

.polyLogin {
  clip-path: circle(67% at 70% 19%);
  background-position: left;
}

.polyLoginSm {
  clip-path: circle(47% at 50% 50%);
  background-position: center;
}

.codeBl-top {
  clip-path: ellipse(51% 98% at 50% 100%);
  background-position: right;
}
.codeBl-bottom {
  clip-path: polygon(0% 0%, 0% 43%, 100% 100%, 100% 0%);
  background-position: right;
}

.docCard-hover {
  clip-path: ellipse(50% 99% at 50% 0%);
}

.docCard-bottom {
  clip-path: ellipse(50% 99% at 50% 0%);
}

.bg-blackTrans {
  background-color: rgba(0, 0, 0, 0.4);
}

.text-shadow {
  text-shadow: 1px 1px 10px rgba(12, 5, 3, 0.8);
}

.scaleCardAnimeReq {
  animation: scaleReq 1s cubic-bezier(0, 1, 0.5, 1) forwards;
  width: 18rem;
}

@keyframes scaleReq {
  0% {
    width: 18rem;
  }
  100% {
    width: 100%;
  }
}
.underlineMila {
  position: relative;
  text-decoration: none;
}

.underlineMila::after {
  border-radius: 1em;
  border-top: 0.1em solid;
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0.05em;
  left: 0;
  transition: right 0.4s cubic-bezier(0, 0.5, 0, 1);
}

.underlineMila:hover::after {
  right: 0;
}

.underlineMila2 {
  position: relative;
  text-decoration: none;
}

.underlineMila2::after {
  border-radius: 1em;
  border-top: 0.1em solid;
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0;
  left: 0;
  transition: right 0.4s cubic-bezier(0, 0.5, 0, 1);
}

.underlineMila2:hover::after {
  right: 0;
}

.fromLeft {
  opacity: 0;
  transform: translateX(4rem);
  transition: transform 4s 0.25s cubic-bezier(0, 1, 0.3, 1),
    opacity 0.3s 0.25s ease-out;
  will-change: transform, opacity;
}

.fromRight {
  opacity: 0;
  transform: translateX(-4rem);
  transition: transform 3s 0.25s cubic-bezier(0, 1, 0.3, 1),
    opacity 0.3s 0.25s ease-out;
  will-change: transform, opacity;
}

.fromDown {
  opacity: 0;
  transform: translateY(4rem);
  transition: transform 4s 0.25s cubic-bezier(0, 1, 0.3, 1),
    opacity 0.3s 0.25s ease-out;
  will-change: transform, opacity;
}

.fromPop {
  animation: pop-in 0.6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  opacity: 0;
  will-change: transform, opacity;
}

.fromLeft.isVisible {
  opacity: 1;
  transform: translateX(0);
}

.fromRight.isVisible {
  opacity: 1;
  transform: translateX(0);
}

.fromDown.isVisible {
  opacity: 1;
  transform: translateY(0);
}

.round1 {
  animation-delay: 1.3s;
}

.round2 {
  animation-delay: 1.5s;
}

.round3 {
  animation-delay: 1.7s;
}

.round4 {
  animation-delay: 1.9s;
}

.round5 {
  animation-delay: 2.1s;
}

.round6 {
  animation-delay: 2.3s;
}

.grecaptcha-badge {
  visibility: hidden;
}
